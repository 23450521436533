import { Layout } from '@leshen/gatsby-theme-contentful'
import { Main } from '@leshen/ui'
import { graphql } from 'gatsby'
import React from 'react'
import OneTrustPrivacyPolicySpanish from '../components/OneTrustPrivacyPolicySpanish'

const PrivacyPolicy = ({ data }) => (
  <Layout
    data={data}
    main={
      <Main>
        <OneTrustPrivacyPolicySpanish />
      </Main>
    }
  />
)

export default PrivacyPolicy

export const query = graphql`
  query OneTrustPrivacy($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
  }
`
